import dayjs from 'dayjs'

import utc from 'dayjs/plugin/utc'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import weekdayPlugin from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(customParseFormat)
dayjs.extend(weekdayPlugin)
dayjs.extend(weekOfYear)
dayjs.extend(timezone)

export {dayjs}
export type {Dayjs} from 'dayjs'
