import {schema} from '~hooks/useForm'

export const supportSchema = schema.object({
	title: schema.string().nonempty(),
	description: schema.string().nonempty(),
})

export const mainSupportSchema = schema.object({
	title: schema.string().nonempty(),
	email: schema.string().email().nonempty(),
	description: schema.string().nonempty(),
})
