import {APP_PERMISSIONS, APP_PERMISSIONS_ACCESSES} from '~types/_app'

export const AppLanguages = {
	en: {
		name: 'English',
	},
	ru: {
		name: 'Русский',
	},
	sl: {
		name: 'Slovenský',
	},
}

export const AppPermissions = {
	[APP_PERMISSIONS.STATISTICS]: {
		locale: 'STATISTICS',
		possibleAccess: [APP_PERMISSIONS_ACCESSES.NOT_ALLOWED, APP_PERMISSIONS_ACCESSES.READ],
	},
	[APP_PERMISSIONS.COURSES]: {
		locale: 'COURSES',
		possibleAccess: [APP_PERMISSIONS_ACCESSES.NOT_ALLOWED, APP_PERMISSIONS_ACCESSES.READ, APP_PERMISSIONS_ACCESSES.WRITE],
	},
	[APP_PERMISSIONS.ARTICLES]: {
		locale: 'ARTICLES',
		possibleAccess: [APP_PERMISSIONS_ACCESSES.NOT_ALLOWED, APP_PERMISSIONS_ACCESSES.READ, APP_PERMISSIONS_ACCESSES.WRITE],
	},
	[APP_PERMISSIONS.COMPANY]: {
		locale: 'COMPANY',
		possibleAccess: [APP_PERMISSIONS_ACCESSES.NOT_ALLOWED, APP_PERMISSIONS_ACCESSES.READ, APP_PERMISSIONS_ACCESSES.WRITE],
	},
	[APP_PERMISSIONS.MANAGERS]: {
		locale: 'MANAGERS',
		possibleAccess: [APP_PERMISSIONS_ACCESSES.NOT_ALLOWED, APP_PERMISSIONS_ACCESSES.READ, APP_PERMISSIONS_ACCESSES.WRITE],
	},
	[APP_PERMISSIONS.USERS]: {
		locale: 'USERS',
		possibleAccess: [APP_PERMISSIONS_ACCESSES.NOT_ALLOWED, APP_PERMISSIONS_ACCESSES.READ, APP_PERMISSIONS_ACCESSES.WRITE],
	},
	[APP_PERMISSIONS.BILLING]: {
		locale: 'BILLING',
		possibleAccess: [APP_PERMISSIONS_ACCESSES.NOT_ALLOWED, APP_PERMISSIONS_ACCESSES.READ, APP_PERMISSIONS_ACCESSES.WRITE],
	},
	[APP_PERMISSIONS.INSPECTIONS]: {
		locale: 'INSPECTIONS',
		possibleAccess: [APP_PERMISSIONS_ACCESSES.NOT_ALLOWED, APP_PERMISSIONS_ACCESSES.READ, APP_PERMISSIONS_ACCESSES.WRITE],
	},
	[APP_PERMISSIONS.FORMS]: {
		locale: 'FORMS',
		possibleAccess: [APP_PERMISSIONS_ACCESSES.NOT_ALLOWED, APP_PERMISSIONS_ACCESSES.READ, APP_PERMISSIONS_ACCESSES.WRITE],
	},
}

export const AppPermissionsAccesses = {
	[APP_PERMISSIONS_ACCESSES.NOT_ALLOWED]: {
		locale: 'NOT_ALLOWED',
	},
	[APP_PERMISSIONS_ACCESSES.WRITE]: {
		locale: 'WRITE',
	},
	[APP_PERMISSIONS_ACCESSES.READ]: {
		locale: 'READ',
	},
}

export const ScheduleStatuses = {
	created: {
		className: 'ui-tag-grey',
		locale: 'CREATED',
	},
	started: {
		className: 'ui-tag-yellow',
		locale: 'STARTED',
	},
	finished: {
		className: 'ui-tag-green',
		locale: 'FINISHED',
	},
	cancelled: {
		className: 'ui-tag-red',
		locale: 'CANCELLED',
	},
}

export const ScheduleTypes = {
	task: {
		locale: 'TASK',
	},
	recheck: {
		locale: 'RECHECK',
	},
	singular: {
		locale: 'SINGULAR',
	},
	course: {
		locale: 'COURSE',
	},
	schedule: {
		locale: 'SCHEDULE',
	},
}

export const BillingPlans = {
	promo: {
		locale: 'PROMO',
	},
}
