import Router from 'next/router'
import {memo, useEffect} from 'react'

import {useAtomValue} from 'jotai'
import {AppState} from '~states/App'
import {UserState} from '~states/User'

const AppCallbacks = () => {
	const app = useAtomValue(AppState)
	const user = useAtomValue(UserState)

	useEffect(() => {
		if (typeof window !== 'undefined' && typeof window['progressbar'] !== 'undefined') {
			if (app['loading']) {
				window['progressbar'].start()
			} else {
				window['progressbar'].done()
			}
		}
	}, [app])

	//TODO: мб переделать на смену глобального стейта? (х3)
	//TODO: добавить игнорирование shallow-переходов в роутинге
	useEffect(() => {
		Router.events.on('routeChangeStart', (url) => {
			window['progressbar'].start()
		})

		Router.events.on('routeChangeComplete', (url) => {
			window['progressbar'].done()
		})

		Router.events.on('routeChangeError', (url) => {
			window['progressbar'].done()
		})
	}, [])

	useEffect(() => {
		if (user?.['device'] && typeof document !== 'undefined') {
			document.querySelector('html').setAttribute('data-device', user['device'])
		}
	}, [user])

	return null
}

export default memo(AppCallbacks)
