import {refreshToken} from '~actions/auth'

import {deleteCookie, getCookies, setCookie} from '~hooks/useCookie'
import {dayjs} from '~hooks/useDate'

import {ManagerInterface} from '~types/manager'

export {nanoid as randomId} from 'nanoid'

export const responseWrapper = async (response) => {
	let data

	try {
		data = await response.json()
	} catch (error) {
		data = {}
	}

	if (response && response.ok) {
		return {
			body: data,
			headers: response.headers,
			status: response.status,
			success: true,
		}
	} else {
		if (data?.error) {
			console.error(data['error'])
		}

		return {
			//TODO: add localization
			body: {
				...data,
				...(!data?.error && {error: 'Something went wrong. Please, repeat later.'}),
			},
			headers: {},
			status: response.status,
			success: false,
		}
	}
}

export const fetchWrapper = async (uri, ctx = null, options = {}, skip_refresh = false, response_wrapper = responseWrapper) => {
	let response

	const cookies = getCookies(ctx && {req: ctx['req'], res: ctx['res']})
	const profileCookiesKeys = ['access_token', 'access_token_expire_at', 'refresh_token', 'user']

	const logoutUser = (profileCookiesKeys, ctx) => {
		profileCookiesKeys.forEach((key) => {
			deleteCookie(key, {
				...(ctx && {req: ctx['req'], res: ctx['res']}),
				...{path: '/'},
			})
		})

		if (typeof window !== 'undefined') {
			window.location.reload()
		}
	}

	//Access-токен может истечь, поэтому проверяем на существование только эти три ключа
	if (['access_token_expire_at', 'refresh_token', 'user'].every((key) => Object.keys(cookies).includes(key))) {
		if (!skip_refresh && dayjs(decodeURIComponent(cookies['access_token_expire_at'])).diff(dayjs(), 'second') < 60) {
			response = await refreshToken(
				{
					refresh_token: cookies['refresh_token'],
				},
				ctx
			)

			if (response['success']) {
				profileCookiesKeys.forEach((key) => {
					if (response['body']?.[key]) {
						cookies[key] =
							typeof response['body'][key] === 'object'
								? key === 'user'
									? JSON.stringify(convertManagerData(response['body'][key]))
									: JSON.stringify(response['body'][key])
								: response['body'][key]

						setCookie(key, cookies[key], {
							...(ctx && {
								req: ctx['req'],
								res: ctx['res'],
							}),
							...(key == 'access_token' &&
								response['body']?.['access_token_expire_at'] && {
									expires: dayjs(response['body']['access_token_expire_at']).toDate(),
								}),
							...{path: '/'},
						})
					}
				})
			} else {
				logoutUser(profileCookiesKeys, ctx)
			}
		}
	} else {
		if (profileCookiesKeys.some((key) => Object.keys(cookies).includes(key))) {
			logoutUser(profileCookiesKeys, ctx)
		}
	}

	if (!options['headers']) {
		options['headers'] = {}
	}

	if (cookies?.['access_token']) {
		options['headers']['Authorization'] = 'Bearer ' + cookies['access_token']
	}

	if (options?.['body'] && options['headers']?.['Content-Type'] && options['headers']['Content-Type'] == 'application/json') {
		Object.entries(options['body']).map(([key, value]) => {
			//TODO: мб нужна еще вот такая штука - options['body'][key] === '' ||
			if ([undefined, null].includes(options['body'][key])) {
				delete options['body'][key]
			}
		})

		options['body'] = JSON.stringify(options['body'])
	}

	try {
		response = await fetch(uri, options)
	} catch (error) {
		console.error(error)

		response = false
	}

	//TODO: Мб добавить здесь обработку 401?

	return await response_wrapper(response)
}

export const fetchQuery = (params = {}) => {
	let query = ''

	Object.entries(params).forEach(([key, value]) => {
		if (value && String(value).length > 0) {
			query += (query ? '&' : '?') + key + '=' + value
		}
	})

	return query
}

export const localeReplace = (string, keys, values) => {
	if (typeof keys !== 'object') {
		keys = [keys]
	}

	keys.map((key, index) => {
		string = string.replaceAll('{{ ' + key + ' }}', typeof values === 'object' ? values[index] : values)
	})

	return string
}

export function declension(number, words) {
	const cases = [2, 0, 1, 1, 1, 2]

	number = parseInt(number)

	return words[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]]
}

export const truncateString = (string = '', beforeSymbols = 0, afterSymbols = 0, soft = false) => {
	if (string.length > beforeSymbols + afterSymbols) {
		//Обрезаем до блжижайшего пробела
		if (soft) {
			let temporaryString
			let indexOfSpace

			if (beforeSymbols > 0) {
				temporaryString = string.slice(beforeSymbols)

				indexOfSpace = temporaryString.indexOf(' ')

				if (indexOfSpace > -1) {
					beforeSymbols += indexOfSpace
				} else {
					beforeSymbols += temporaryString.length - 1
				}
			}
			/*
			if (afterSymbols > 0) {
				//TODO	
			}
			*/
		}

		return (beforeSymbols > 0 ? string.substring(0, beforeSymbols) : '') + '...' + (afterSymbols > 0 ? string.substring(string.length - afterSymbols, string.length) : '')
	} else {
		return string
	}
}

export const makeTreeFromArray = (array = [], id_key = 'id', parent_key = 'parent_id', childrens_array_key = 'childrens') => {
	const map = {}
	const tree = []

	array.forEach((item) => {
		map[item[id_key]] = {
			...item,
		}
		map[item[id_key]][childrens_array_key] = []
	})

	array.forEach((item) => {
		if (item?.[parent_key]) {
			map[item[parent_key]][childrens_array_key].push(map[item[id_key]])
		} else {
			tree.push(map[item[id_key]])
		}
	})

	return tree
}

export const formatSecondsToString = (seconds: number): string => {
	if (!seconds) return ''
	const hours = Math.floor(seconds / 3600)
	const minutes = Math.floor((seconds % 3600) / 60)

	const formattedHours = String(hours).padStart(2, '0')
	const formattedMinutes = String(minutes).padStart(2, '0')

	return formattedHours + ':' + formattedMinutes
}

export const swapArrayItems = function (array, firstIndex, secondIndex): any[] {
	let temp = array[firstIndex]

	array[firstIndex] = array[secondIndex]
	array[secondIndex] = temp

	return array
}

export async function getImageAspectRatio(imageUrl) {
	return new Promise((resolve, reject) => {
		const img = new Image()
		img.onload = () => {
			const width = img.naturalWidth
			const height = img.naturalHeight

			let aspectRatio

			if (width > height) {
				aspectRatio = width / height
			} else {
				aspectRatio = height / width
			}
			resolve(aspectRatio)
		}
		img.onerror = (error) => {
			reject(error)
		}
		img.src = imageUrl
	})
}

export const convertManagerData = (data: ManagerInterface) => {
	return {
		id: data?.['id'],
		permissions: data?.['permissions'],
		name: data?.['name'],
		email: data?.['email'],
		tenant: data?.['tenant'],
	}
}
