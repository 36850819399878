import {useAtomValue} from 'jotai'
import {AppState} from '~states/App'
import {LocaleState} from '~states/Locale'

import {resolver, schema, useForm} from '~hooks/useForm'

import {supportSchema} from '~schemas/_app'

import {Button} from '~ui/Forms'
import {Icon} from '~ui/Modules'

import SupportForm from '~chunks/_app/form/Support'

interface Props {
	onClose: () => void
	onCancel: () => void
	onSubmit: (data: FormData, resetForm: any) => Promise<void>
	className?: string
}

type FormData = schema.infer<typeof supportSchema>

const SupportModal = ({onClose, onCancel, onSubmit, className = '', ...props}: Props) => {
	const locale = useAtomValue(LocaleState)
	const app = useAtomValue(AppState)

	const {
		register: formRegister,
		formState: {errors: formErrors},
		handleSubmit: formHandleSubmit,
		reset: formReset,
	} = useForm<FormData>({
		resolver: resolver(supportSchema),
	})

	return (
		<div className={'ui-modal tw-w-[600px]' + (className && ' ' + className)} {...props}>		
			<button type="button" onClick={onClose} className="ui-modal-button-close">
				<Icon name="#icon-xmark" />
			</button>
			
			<form onSubmit={formHandleSubmit((data) => onSubmit(data, formReset))} className="tw-flex tw-flex-col tw-gap-y-[60px]" autoComplete="off">
				<div className="tw-flex tw-flex-col tw-gap-y-[24px]">
					<h2 className="ui-h3">{locale['SUPPORT']['TITLE']}</h2>

					<SupportForm
						formHandleSubmit={formHandleSubmit}
						onSubmit={onSubmit}
						formReset={formReset}
						formRegister={formRegister}
						formErrors={formErrors}
					/>
				</div>

				<div className="tw-flex tw-gap-x-[16px]">
					<Button type="submit" className="ui-button ui-button-primary" onClick={() => formHandleSubmit((data) => onSubmit(data, formReset))()} loading={app['loading']}>
						{locale['SUPPORT']['FORMS']['DEFAULT']['SUBMIT']}
					</Button>
					<button type="button" className="ui-button ui-button-secondary" onClick={onCancel}>
						{locale['SUPPORT']['FORMS']['DEFAULT']['CANCEL']}
					</button>
				</div>
			</form>
		</div>
	)
}

export default SupportModal
