//TODO: загружать не все разом, а динамически в зависимости от языка (лейзи)
import 'dayjs/locale/ru'

import {useHydrateAtoms} from 'jotai/utils'
import {AppState} from '~states/App'
import {LanguageState} from '~states/Language'
import {UserState} from '~states/User'

import {isMobile} from '~hooks/useDevice'
import {dayjs} from '~hooks/useDate'
import {AppLanguages} from '~helpers/constants'

interface Props {
	cookies: any
	userAgent?: string
}

const AppHydration = ({cookies = {}, userAgent = null}: Props) => {
	const device = isMobile(userAgent).any

	dayjs.locale(cookies?.['user.lang'] ? cookies['user.lang'] : Object.keys(AppLanguages)[0])

	useHydrateAtoms([
		[LanguageState, cookies?.['user.lang'] ? cookies['user.lang'] : Object.keys(AppLanguages)[0]],
		[
			UserState,
			{
				//TODO: переделать ключ на user.profile
				profile: cookies?.['user'] ? JSON.parse(decodeURIComponent(cookies['user'])) : null,
				//TODO: временный костыль
				device: device ? 'mobile' : 'desktop',
			},
		],
		[
			AppState,
			{
				init: false,
				loading: false,
				aside: {
					opened: cookies?.['aside.opened'] ? JSON.parse(cookies['aside.opened']) : [],
				},
				filters: {
					opened: cookies?.['filters.opened'] ? JSON.parse(cookies['filters.opened']) : false,
				},
			},
		],
	])

	return null
}

export default AppHydration
