import {ComponentProps, CSSProperties, ForwardedRef, forwardRef, ReactNode} from 'react'

interface IconInterface extends ComponentProps<'svg'> {
	ariaHidden?: 'true'
	children?: ReactNode
	className?: string
	focusable?: 'false'
	name: string
	role?: 'img'
	style?: CSSProperties
}

const Icon = forwardRef(
	({name = '', className = '', children = null, ariaHidden = 'true', focusable = 'false', role = 'img', style = {}, ...props}: IconInterface, ref: ForwardedRef<SVGSVGElement>) => {
		return (
			<svg ref={ref} aria-hidden={ariaHidden} focusable={focusable} role={role} {...props} className={className} style={style}>
				{children && <>{children}</>}
				{!children && name.length > 0 && <use xlinkHref={name} />}
			</svg>
		)
	}
)

export {Icon}
export type {IconInterface}
