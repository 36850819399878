import {memo, CSSProperties, FC} from 'react'

import {Notification, NotificationType, NotificationPosition} from '../types'

import {Icon} from '~ui/Modules'

interface NotificationItemProps {
	notification: Notification
	onClick: any
	position?: NotificationPosition
}

const getAnimationStyle = (visible: boolean): CSSProperties => {
	return {
		animation: visible ? `0.35s cubic-bezier(.21,1.02,.73,1) forwards` : `0.4s forwards cubic-bezier(.06,.71,.55,1)`,
	}
}

//TODO: remove status, and allow to pass custom classes
const typesClassList: {
	[key in NotificationType]: string
} = {
	success: 'ui-notification ui-notification-success',
	info: 'ui-notification ui-notification-info',
	error: 'ui-notification ui-notification-error',
	loading: 'ui-notification ui-notification-loading',
}

const NotificationItem: FC<NotificationItemProps> = memo(({notification, onClick, position}) => {
	const {height, visible, type, style, icon, title, content} = notification

	const animationStyle: CSSProperties = height ? getAnimationStyle(visible) : {opacity: 0}

	//TODO: Добавить в будущем определение иконки И связать её с промисными уведомлениями (индикатор загрузки -> успех/фейл)
	//TODO: Добавить возможность указать кнопку закрытия отдельным параметром (по умолчанию все уведолмение = кнопка закрытия

	//TODO: вынести  ui-notification-primary в передаваемый класс сверху
	return (
		<div
			onClick={onClick}
			className={typesClassList[type] + (visible ? ' tw-animate-enter' : ' tw-animate-leave') + ' ui-notification-primary ui-notification'}
			style={{
				...animationStyle,
				...style,
			}}
		>
			<div className="tw-flex tw-items-center tw-gap-x-[10px]">
				{icon && <Icon name={icon.id} className="tw-h-[32px] tw-w-[32px]" />}
				<div className="tw-flex tw-flex-col tw-gap-y-[10px]">
					{title && <div className="ui-notification-title">{title}</div>}
					<div>{content}</div>
				</div>
			</div>
		</div>
	)
})

export {NotificationItem}
