import {forwardRef, ReactNode, TextareaHTMLAttributes} from 'react'

interface Props extends TextareaHTMLAttributes<HTMLTextAreaElement> {
	className?: string
	children?: ReactNode
}

const Textarea = forwardRef<HTMLTextAreaElement, Props>(({className = '', children = null, disabled = false, readOnly = false, ...props}, ref) => {
	//TODO: add ability to autoheight resize

	return (
		<label className={'ui-textarea' + (className && ' ' + className) + (disabled ? ' ui-textarea-disabled' : '') + (readOnly ? ' ui-textarea-read-only' : '')}>
			<textarea ref={ref} {...props} />

			{children}
		</label>
	)
})

export {Textarea}
