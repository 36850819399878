import Head from 'next/head'
import {useEffect} from 'react'

import {Provider, useAtom} from 'jotai'
import {AppState} from '~states/App'

import {getCookies, deleteCookie} from '~hooks/useCookie'

import {Portal} from '~ui/Modules'
//TODO: rework
import NotificationContainer from '~ui/modules/notification/views/Container'

import {FontFPTRootUI} from '~chunks/_app/_fonts'
import AppHydration from '~chunks/_app/_hydration'
import AppCallbacks from '~chunks/_app/_callbacks'
import AppProgress from '~chunks/_app/Progress'
import AppIcons from '~chunks/_app/Icons'
import AppModals from '~chunks/_app/Modals'
import AppSidebars from '~chunks/_app/Sidebars'

import '~styles/app.css'

//TODO: кажется, оно все равно инициализируется миллион раз - переделать, когда некст станет умнее
App.getInitialProps = (context) => {
	const cookies = getCookies(context?.['ctx'])
	const userAgent = context?.['ctx']?.['req']?.['headers']?.['user-agent']

	return {
		cookies,
		userAgent,
	}
}

function App({Component, pageProps, cookies, userAgent}) {
	const [app, setApp] = useAtom(AppState)

	//TODO: вынести в будущем в миддлвар
	if (cookies['reload_page'] && typeof window !== 'undefined') {
		deleteCookie('reload_page', {
			path: '/',
		})

		window.location.reload()
	}

	useEffect(() => {
		setApp((app) => ({
			...app,
			init: true,
		}))
	}, [setApp])

	return (
		<>
			<Head>
				<meta charSet="UTF-8" />
				<meta httpEquiv="X-UA-Compatible" content="IE=edge" />
				<meta name="DC.format" content="text/html" />
				<meta name="DC.coverage" content="World" />

				<meta property="og:site_name" content={process.env.NEXT_PUBLIC_APP_NAME} />
				<meta name="DC.Creator" content={process.env.NEXT_PUBLIC_APP_NAME} />

				<meta name="viewport" content="initial-scale=1.0, width=device-width" />
				<meta name="google" content="notranslate" />
				<meta name="format-detection" content="telephone=no" />
				<meta httpEquiv="x-rim-auto-match" content="none" />

				<link rel="shortcut icon" href="/favicon.ico" />
				<link rel="apple-touch-icon" sizes="180x180" href="/assets/favicons/apple-touch-icon.png" />
				<link rel="icon" type="image/png" sizes="32x32" href="/assets/favicons/favicon-32x32.png" />
				<link rel="icon" type="image/png" sizes="16x16" href="/assets/favicons/favicon-16x16.png" />
				<link rel="manifest" href="/assets/favicons/site.webmanifest" />
				<link rel="mask-icon" href="/assets/favicons/safari-pinned-tab.svg" color="#736aed" />
				<meta name="theme-color" content="#ffffff" />
				<meta name="msapplication-TileColor" content="#736aed" />
				<meta name="msapplication-config" content="/assets/favicons/browserconfig.xml" />

				{/*TODO: get from locale*/}
				{/*
				<meta name="DC.Language" content="ru-RU" />
				<meta property="og:locale" content="ru_RU" />
				*/}
			</Head>

			<Provider>
				{!app?.['init'] && <AppHydration userAgent={userAgent} cookies={cookies} />}

				<AppCallbacks />

				<AppProgress />

				<div className={'tw-relative tw-flex tw-min-h-screen tw-w-full' + (FontFPTRootUI.className && ' ' + FontFPTRootUI.className)}>
					<Component {...pageProps} />

					<AppIcons />
					<AppModals />
					<AppSidebars />

					<Portal>
						<NotificationContainer containerClassName="tw-fixed tw-w-full tw-max-w-[26rem] tw-inset-y-2 tw-right-0 tw-pointer-events-none tw-z-[80] tw-overflow-hidden tw-select-none" />
					</Portal>

					{/*temporary hack*/}
					<div id="headlessui-portal-root">
						<div />
					</div>
				</div>
			</Provider>
		</>
	)
}

export default App
