import {ComponentProps, memo, ReactNode} from 'react'

import {Portal} from '~ui/Modules'

interface Props extends ComponentProps<'div'> {
	children: ReactNode
	className?: string
	isOpen: boolean
}

const Sidebar = memo(({children, className = '', isOpen, ...props}: Props) => {
	return (
		<Portal>
			<div className={'ui-sidebar' + (isOpen ? ' ui-sidebar-opened' : '') + (className && ' ' + className)}>{children}</div>
		</Portal>
	)
})

export {Sidebar}
