// @ts-nocheck

const amplitudeSetUserProperties = (data = null, userId = null) => {
	if (process.env.NEXT_PUBLIC_APP_MODE === 'production' && typeof window !== 'undefined' && typeof window.amplitude !== 'undefined') {
		data ? window.amplitude.setUserProperties(data) : window.amplitude.setUserProperties()
		userId ? window.amplitude.setUserId(userId) : window.amplitude.setUserId()
	}
}

const amplitudeTrackEvent = (eventName, eventProperties = {}) => {
	if (process.env.NEXT_PUBLIC_APP_MODE === 'production' && typeof window !== 'undefined' && typeof window?.amplitude?.track !== 'undefined') {
		window.amplitude.track(eventName, eventProperties)
	}
}

export {amplitudeSetUserProperties, amplitudeTrackEvent}
