import {forwardRef, ButtonHTMLAttributes, ReactNode} from 'react'

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
	type?: 'button' | 'reset' | 'submit'
	loading?: boolean
	disabled?: boolean
	children?: ReactNode
	className?: string
}

const Button = forwardRef<HTMLInputElement, Props>(({type = 'button', loading = false, disabled = false, children = null, className = '', ...props}, ref) => {
	return (
		<button type={type} disabled={disabled || loading} className={'ui-button' + (className && ' ' + className) + (loading ? ' ui-button-loading' : '')} {...props}>
			{children}

			{loading && <div className="ui-spinner"></div>}
		</button>
	)
})

export {Button}
