interface Props {
	className?: string
}

const AppProgress = ({className = '', ...props}: Props) => {
	return (
		<div id="app-progress-bar" className={'tw-invisible tw-fixed tw-inset-x-0 tw-top-0 tw-z-[80] tw-opacity-0 tw-transition-opacity tw-duration-300' + (className && ' ' + className)} {...props}>
			<div className="tw-h-[4px] tw-bg-surface-4" role="indicator" style={{transition: 'all 200ms linear 0s', transform: 'translate3d(-100%, 0px, 0px)'}}></div>
		</div>
	)
}

export default AppProgress
