import {z as schema} from 'zod'

export * from 'react-hook-form'

export {zodResolver as resolver} from '@hookform/resolvers/zod'

export {default as useFormPersist} from 'react-hook-form-persist'

export type {Control, FieldErrors, UseFormHandleSubmit} from 'react-hook-form'

const customErrorMap: schema.ZodErrorMap = (error, ctx) => {
	switch (error.code) {
		case schema.ZodIssueCode.invalid_type:
			return {message: error.expected}

		case schema.ZodIssueCode.unrecognized_keys:
			return {message: error.keys.join(',')}

		case schema.ZodIssueCode.invalid_union:
			return {message: error.unionErrors.join(',')}

		case schema.ZodIssueCode.invalid_enum_value:
			return {message: error.options.join(',')}

		case schema.ZodIssueCode.invalid_string:
			return {message: String(error.validation)}

		case schema.ZodIssueCode.too_small:
			return {message: String(error.minimum)}

		case schema.ZodIssueCode.too_big:
			return {message: String(error.maximum)}

		case schema.ZodIssueCode.not_multiple_of:
			return {message: String(error.multipleOf)}

		default:
			return {message: ''}
	}
}

schema.setErrorMap(customErrorMap)

export {schema}
