import {forwardRef, InputHTMLAttributes, ReactNode} from 'react'

import {Icon} from '~ui/Modules'

interface InputInterface extends InputHTMLAttributes<HTMLInputElement> {
	type?: string
	icon?: string
	className?: string
	children?: ReactNode
}

const Input = forwardRef<HTMLInputElement, InputInterface>(({id = '', type = 'text', icon = '', className = '', children = null, disabled = false, readOnly = false, ...props}, ref) => {
	return (
		<label className={'ui-input' + (className && ' ' + className) + (disabled ? ' ui-input-disabled' : '') + (readOnly ? ' ui-input-read-only' : '')}>
			{icon.length > 0 && <Icon className="ui-input-icon" name={icon} />}

			<input type={type} disabled={disabled} readOnly={readOnly} ref={ref} {...props} />

			{children}
		</label>
	)
})

export {Input}
export type {InputInterface}
