const AppIcons = () => {
	return (
		<svg className="tw-absolute tw-hidden" xmlns="http://www.w3.org/2000/svg">
			<symbol id="icon-app-logo" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
				<path d="M32 32L0 64h64L32 32z" fill="#BCFE2F" />
				<path d="M64 0L32 32l32 32V0z" fill="#BFB9F3" />
				<path d="M32 0L0 32V0h32z" fill="#FF0074" />
				<path d="M0 32l16 16 16-16-16-16L0 32z" fill="#BFB9F3" />
				<path d="M16 16l16 16 16-16H16z" fill="#736AED" />
				<path d="M32 0L16 16h32L64 0H32z" fill="#BCFE2F" />
				<path d="M0 32v32l16-16L0 32z" fill="#FF0074" />
			</symbol>

			<symbol id="icon-bars" viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg">
				<path
					fill="currentColor"
					d="M0 80C0 71.16 7.164 64 16 64H432C440.8 64 448 71.16 448 80C448 88.84 440.8 96 432 96H16C7.164 96 0 88.84 0 80zM0 240C0 231.2 7.164 224 16 224H432C440.8 224 448 231.2 448 240C448 248.8 440.8 256 432 256H16C7.164 256 0 248.8 0 240zM432 416H16C7.164 416 0 408.8 0 400C0 391.2 7.164 384 16 384H432C440.8 384 448 391.2 448 400C448 408.8 440.8 416 432 416z"
				/>
			</symbol>
			<symbol id="icon-minus" viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg">
				<path fill="currentColor" d="M432 256C432 264.8 424.8 272 416 272H32c-8.844 0-16-7.15-16-15.99C16 247.2 23.16 240 32 240h384C424.8 240 432 247.2 432 256z" />
			</symbol>
			<symbol id="icon-plus" viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg">
				<path
					fill="currentColor"
					d="M432 256C432 264.8 424.8 272 416 272h-176V448c0 8.844-7.156 16.01-16 16.01S208 456.8 208 448V272H32c-8.844 0-16-7.15-16-15.99C16 247.2 23.16 240 32 240h176V64c0-8.844 7.156-15.99 16-15.99S240 55.16 240 64v176H416C424.8 240 432 247.2 432 256z"
				/>
			</symbol>
			<symbol id="icon-circle-xmark" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
				<path
					fill="currentColor"
					d="M256 32a224 224 0 1 1 0 448 224 224 0 1 1 0-448zm0 480A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM180.7 180.7c-6.2 6.2-6.2 16.4 0 22.6L233.4 256l-52.7 52.7c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0L256 278.6l52.7 52.7c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6L278.6 256l52.7-52.7c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0L256 233.4l-52.7-52.7c-6.2-6.2-16.4-6.2-22.6 0z"
				/>
			</symbol>
			<symbol id="icon-gear" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
				<path
					fill="currentColor"
					d="M168 255.1C168 207.4 207.4 167.1 256 167.1C304.6 167.1 344 207.4 344 255.1C344 304.6 304.6 344 256 344C207.4 344 168 304.6 168 255.1zM256 199.1C225.1 199.1 200 225.1 200 255.1C200 286.9 225.1 311.1 256 311.1C286.9 311.1 312 286.9 312 255.1C312 225.1 286.9 199.1 256 199.1zM65.67 230.6L25.34 193.8C14.22 183.7 11.66 167.2 19.18 154.2L49.42 101.8C56.94 88.78 72.51 82.75 86.84 87.32L138.8 103.9C152.2 93.56 167 84.96 182.8 78.43L194.5 25.16C197.7 10.47 210.7 0 225.8 0H286.2C301.3 0 314.3 10.47 317.5 25.16L329.2 78.43C344.1 84.96 359.8 93.56 373.2 103.9L425.2 87.32C439.5 82.75 455.1 88.78 462.6 101.8L492.8 154.2C500.3 167.2 497.8 183.7 486.7 193.8L446.3 230.6C447.4 238.9 448 247.4 448 255.1C448 264.6 447.4 273.1 446.3 281.4L486.7 318.2C497.8 328.3 500.3 344.8 492.8 357.8L462.6 410.2C455.1 423.2 439.5 429.2 425.2 424.7L373.2 408.1C359.8 418.4 344.1 427 329.2 433.6L317.5 486.8C314.3 501.5 301.3 512 286.2 512H225.8C210.7 512 197.7 501.5 194.5 486.8L182.8 433.6C167 427 152.2 418.4 138.8 408.1L86.84 424.7C72.51 429.2 56.94 423.2 49.42 410.2L19.18 357.8C11.66 344.8 14.22 328.3 25.34 318.2L65.67 281.4C64.57 273.1 64 264.6 64 255.1C64 247.4 64.57 238.9 65.67 230.6V230.6zM158.4 129.2L145.1 139.5L77.13 117.8L46.89 170.2L99.58 218.2L97.39 234.8C96.47 241.7 96 248.8 96 255.1C96 263.2 96.47 270.3 97.39 277.2L99.58 293.8L46.89 341.8L77.13 394.2L145.1 372.5L158.4 382.8C169.5 391.4 181.9 398.6 195 403.1L210.5 410.4L225.8 480H286.2L301.5 410.4L316.1 403.1C330.1 398.6 342.5 391.4 353.6 382.8L366.9 372.5L434.9 394.2L465.1 341.8L412.4 293.8L414.6 277.2C415.5 270.3 416 263.2 416 256C416 248.8 415.5 241.7 414.6 234.8L412.4 218.2L465.1 170.2L434.9 117.8L366.9 139.5L353.6 129.2C342.5 120.6 330.1 113.4 316.1 108L301.5 101.6L286.2 32H225.8L210.5 101.6L195 108C181.9 113.4 169.5 120.6 158.4 129.2H158.4z"
				/>
			</symbol>
			<symbol id="icon-user-headset" viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg">
				<path
					fill="currentColor"
					d="M223.7 96h.6387c37.75 0 71.97 22.1 87.43 56.63c15.38 34.63 8.982 75.33-16.39 103.3c0 .125 0-.125 0 0L304 256c16.5 0 31.9-8.6 40.52-22.6C349 220.4 352 206.6 352 192c0-70.75-57.25-128-128-128s-128 57.25-128 128c0 40.38 19.12 75.99 48.37 99.49C144.2 290.2 144 289.3 144 288C144.1 276.8 147.1 265.6 152.9 256c-25.5-28-32.13-68.5-16.75-103.3C151.6 118.2 185.8 96 223.7 96zM48 224C56.88 224 64 216.9 64 208V192c0-88.25 71.75-160 160-160s160 71.75 160 160v16C384 252.1 348.1 288 304 288h-32c0-17.62-14.38-32-32-32h-32c-17.62 0-32 14.38-32 32s14.38 32 32 32h96c61.88-.125 111.9-50.13 112-112V192c0-105.9-86.13-192-192-192S32 86.13 32 192v16C32 216.9 39.13 224 48 224zM314.7 352H133.3C59.7 352 0 411.7 0 485.3C0 500.1 11.94 512 26.66 512H421.3C436.1 512 448 500.1 448 485.3C448 411.7 388.3 352 314.7 352zM32.14 480c2.779-53.41 47.11-96 101.2-96h181.3c54.09 0 98.42 42.59 101.2 96H32.14z"
				/>
			</symbol>
			<symbol id="icon-calendar-check" viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg">
				<path
					fill="currentColor"
					d="M219.3 395.3C213.1 401.6 202.9 401.6 196.7 395.3L132.7 331.3C126.4 325.1 126.4 314.9 132.7 308.7C138.9 302.4 149.1 302.4 155.3 308.7L208 361.4L308.7 260.7C314.9 254.4 325.1 254.4 331.3 260.7C337.6 266.9 337.6 277.1 331.3 283.3L219.3 395.3zM128 64H320V16C320 7.164 327.2 0 336 0C344.8 0 352 7.164 352 16V64H384C419.3 64 448 92.65 448 128V448C448 483.3 419.3 512 384 512H64C28.65 512 0 483.3 0 448V128C0 92.65 28.65 64 64 64H96V16C96 7.164 103.2 0 112 0C120.8 0 128 7.164 128 16V64zM32 448C32 465.7 46.33 480 64 480H384C401.7 480 416 465.7 416 448V192H32V448zM32 128V160H416V128C416 110.3 401.7 96 384 96H64C46.33 96 32 110.3 32 128z"
				/>
			</symbol>
			<symbol id="icon-file" viewBox="0 0 384 512" xmlns="http://www.w3.org/2000/svg">
				<path
					fill="currentColor"
					d="M0 64C0 28.65 28.65 0 64 0H220.1C232.8 0 245.1 5.057 254.1 14.06L369.9 129.9C378.9 138.9 384 151.2 384 163.9V448C384 483.3 355.3 512 320 512H64C28.65 512 0 483.3 0 448V64zM352 192H240C213.5 192 192 170.5 192 144V32H64C46.33 32 32 46.33 32 64V448C32 465.7 46.33 480 64 480H320C337.7 480 352 465.7 352 448V192zM347.3 152.6L231.4 36.69C229.4 34.62 226.8 33.18 224 32.48V144C224 152.8 231.2 160 240 160H351.5C350.8 157.2 349.4 154.6 347.3 152.6z"
				/>
			</symbol>
			<symbol id="icon-newspaper" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
				<path
					fill="currentColor"
					d="M464 32h-320C117.5 32 96 53.53 96 80V416c0 17.66-14.36 32-32 32s-32-14.34-32-32V112C32 103.2 24.84 96 16 96S0 103.2 0 112V416c0 35.28 28.7 64 64 64h368c44.11 0 80-35.88 80-80v-320C512 53.53 490.5 32 464 32zM480 400c0 26.47-21.53 48-48 48H119.4C124.9 438.6 128 427.7 128 416V80C128 71.19 135.2 64 144 64h320C472.8 64 480 71.19 480 80V400zM272 304h-96C167.2 304 160 311.2 160 320s7.156 16 16 16h96c8.844 0 16-7.156 16-16S280.8 304 272 304zM432 304h-96C327.2 304 320 311.2 320 320s7.156 16 16 16h96c8.844 0 16-7.156 16-16S440.8 304 432 304zM272 368h-96C167.2 368 160 375.2 160 384s7.156 16 16 16h96c8.844 0 16-7.156 16-16S280.8 368 272 368zM432 368h-96c-8.844 0-16 7.156-16 16s7.156 16 16 16h96c8.844 0 16-7.156 16-16S440.8 368 432 368zM416 96H192C174.3 96 160 110.3 160 128v96c0 17.67 14.33 32 32 32h224c17.67 0 32-14.33 32-32V128C448 110.3 433.7 96 416 96zM416 224H192V128h224V224z"
				/>
			</symbol>
			<symbol id="icon-grip-dots-vertical" viewBox="0 0 256 512" xmlns="http://www.w3.org/2000/svg">
				<path
					fill="currentColor"
					d="M32 96C32 78.33 46.33 64 64 64C81.67 64 96 78.33 96 96C96 113.7 81.67 128 64 128C46.33 128 32 113.7 32 96zM32 256C32 238.3 46.33 224 64 224C81.67 224 96 238.3 96 256C96 273.7 81.67 288 64 288C46.33 288 32 273.7 32 256zM96 416C96 433.7 81.67 448 64 448C46.33 448 32 433.7 32 416C32 398.3 46.33 384 64 384C81.67 384 96 398.3 96 416zM160 96C160 78.33 174.3 64 192 64C209.7 64 224 78.33 224 96C224 113.7 209.7 128 192 128C174.3 128 160 113.7 160 96zM224 256C224 273.7 209.7 288 192 288C174.3 288 160 273.7 160 256C160 238.3 174.3 224 192 224C209.7 224 224 238.3 224 256zM160 416C160 398.3 174.3 384 192 384C209.7 384 224 398.3 224 416C224 433.7 209.7 448 192 448C174.3 448 160 433.7 160 416z"
				/>
			</symbol>
			<symbol id="icon-user" viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg">
				<path
					fill="currentColor"
					d="M224 256c70.7 0 128-57.31 128-128s-57.3-128-128-128C153.3 0 96 57.31 96 128S153.3 256 224 256zM224 32c52.94 0 96 43.06 96 96c0 52.93-43.06 96-96 96S128 180.9 128 128C128 75.06 171.1 32 224 32zM274.7 304H173.3C77.61 304 0 381.6 0 477.3c0 19.14 15.52 34.67 34.66 34.67h378.7C432.5 512 448 496.5 448 477.3C448 381.6 370.4 304 274.7 304zM413.3 480H34.66C33.2 480 32 478.8 32 477.3C32 399.4 95.4 336 173.3 336h101.3C352.6 336 416 399.4 416 477.3C416 478.8 414.8 480 413.3 480z"
				/>
			</symbol>
			<symbol id="icon-folder" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
				<path
					fill="currentColor"
					d="M447.1 96H272L226.7 50.75C214.7 38.74 198.5 32 181.5 32H63.1c-35.35 0-64 28.65-64 64v320c0 35.35 28.65 64 64 64h384c35.35 0 64-28.65 64-64V160C511.1 124.7 483.3 96 447.1 96zM480 416c0 17.64-14.36 32-32 32H64c-17.64 0-32-14.36-32-32V96c0-17.64 14.36-32 32-32h117.5c8.549 0 16.58 3.328 22.63 9.375L258.7 128H448c17.64 0 32 14.36 32 32V416z"
				/>
			</symbol>
			<symbol id="icon-list-ol" viewBox="0 0 576 512" xmlns="http://www.w3.org/2000/svg">
				<path
					fill="currentColor"
					d="M64 48.04C64 39.2 71.16 32.04 80 32.04H112C120.8 32.04 128 39.2 128 48.04V192H160C168.8 192 176 199.2 176 208C176 216.9 168.8 224 160 224H64C55.16 224 48 216.9 48 208C48 199.2 55.16 192 64 192H96V64.04H80C71.16 64.04 64 56.87 64 48.04V48.04zM224 96C224 87.16 231.2 80 240 80H528C536.8 80 544 87.16 544 96C544 104.8 536.8 112 528 112H240C231.2 112 224 104.8 224 96zM224 256C224 247.2 231.2 240 240 240H528C536.8 240 544 247.2 544 256C544 264.8 536.8 272 528 272H240C231.2 272 224 264.8 224 256zM224 416C224 407.2 231.2 400 240 400H528C536.8 400 544 407.2 544 416C544 424.8 536.8 432 528 432H240C231.2 432 224 424.8 224 416zM75.31 339.3C69.07 345.6 58.94 345.6 52.69 339.3C46.44 333.1 46.44 322.9 52.69 316.7L68.1 301.3C92.28 277.1 131.9 278.4 154.4 304.1C174.4 326.1 173.8 361.2 153.1 383.4L92.82 448H160C168.8 448 176 455.2 176 464C176 472.8 168.8 480 160 480H56C49.63 480 43.87 476.2 41.33 470.4C38.79 464.5 39.96 457.7 44.3 453.1L129.7 361.6C139.2 351.4 139.5 335.7 130.3 325.2C119.1 313.4 101.8 312.8 90.73 323.9L75.31 339.3z"
				/>
			</symbol>
			<symbol id="icon-image" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
				<path
					fill="currentColor"
					d="M324.9 157.8c-11.38-17.38-39.89-17.31-51.23-.0625L200.5 268.5L184.1 245.9C172.7 229.1 145.9 229.9 134.4 245.9l-64.52 89.16c-6.797 9.406-7.75 21.72-2.547 32C72.53 377.5 83.05 384 94.75 384h322.5c11.41 0 21.8-6.281 27.14-16.38c5.312-10 4.734-22.09-1.516-31.56L324.9 157.8zM95.8 352l62.39-87.38l29.91 41.34C191.2 310.2 196.4 313.2 201.4 312.6c5.25-.125 10.12-2.781 13.02-7.188l83.83-129.9L415 352H95.8zM447.1 32h-384C28.65 32-.0091 60.65-.0091 96v320c0 35.35 28.65 64 63.1 64h384c35.35 0 64-28.65 64-64V96C511.1 60.65 483.3 32 447.1 32zM480 416c0 17.64-14.36 32-32 32H64c-17.64 0-32-14.36-32-32V96c0-17.64 14.36-32 32-32h384c17.64 0 32 14.36 32 32V416zM144 192C170.5 192 192 170.5 192 144S170.5 96 144 96S96 117.5 96 144S117.5 192 144 192zM144 128c8.822 0 15.1 7.178 15.1 16S152.8 160 144 160S128 152.8 128 144S135.2 128 144 128z"
				/>
			</symbol>
			<symbol id="icon-music" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
				<path
					fill="currentColor"
					d="M481.5 0c-3.25 0-6.375 .5-9.25 1.5l-290.9 96C168.8 101.8 160 114 160 128v244.8C143 360 120.7 352 95.1 352C42.1 352 0 387.8 0 432S42.1 512 95.1 512s95.1-35.7 95.1-79.95L192 256l288-96v148.8C463 296 440.7 288 415.1 288c-53 0-95.1 35.75-95.1 79.1s42.1 79.1 95.1 79.1s95.1-35.75 95.1-80L512 31.99C512 13.74 497.8 0 481.5 0zM96 480c-34.75 0-64-21.1-64-48C32 406 61.25 384 96 384s64 22 64 48C160 458 130.8 480 96 480zM416 416c-34.75 0-64-21.1-64-48c0-25.1 29.25-48 64-48s64 22 64 48C480 394 450.8 416 416 416zM480 126.2l-288 96V128H191.5L480 32.62V126.2z"
				/>
			</symbol>
			<symbol id="icon-video" viewBox="0 0 576 512" xmlns="http://www.w3.org/2000/svg">
				<path
					fill="currentColor"
					d="M558.8 99.64c-10.59-5.484-23.37-4.76-33.15 2.099l-102.8 72.04c-7.25 5.062-9 15.05-3.938 22.28C423.1 203.3 433.9 205 441.2 200L544 128v255.9L441.2 312c-7.266-5.047-17.22-3.312-22.28 3.938c-5.062 7.234-3.312 17.22 3.938 22.28l102.8 71.98c5.5 3.844 11.94 5.786 18.38 5.786c5.047 0 10.12-1.203 14.78-3.625C569.4 406.8 576 395.1 576 383.1V128C576 116 569.4 105.2 558.8 99.64zM320 64H64C28.65 64 0 92.65 0 128v256c0 35.35 28.65 64 64 64h256c35.35 0 64-28.65 64-64V128C384 92.65 355.3 64 320 64zM352 384c0 17.64-14.36 32-32 32H64c-17.64 0-32-14.36-32-32V128c0-17.64 14.36-32 32-32h256c17.64 0 32 14.36 32 32V384z"
				/>
			</symbol>
			<symbol id="icon-book" viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg">
				<path
					fill="currentColor"
					d="M448 368v-320C448 21.49 426.5 0 400 0h-320C35.82 0 0 35.82 0 80V448c0 35.35 28.65 64 64 64h368c8.844 0 16-7.156 16-16S440.8 480 432 480H416v-66.95C434.6 406.4 448 388.8 448 368zM32 80C32 53.49 53.49 32 80 32H96v352H64c-11.71 0-22.55 3.389-32 8.9V80zM384 480H64c-17.64 0-32-14.36-32-32s14.36-32 32-32h320V480zM400 384H128V32h272C408.8 32 416 39.17 416 48v320C416 376.8 408.8 384 400 384zM352 128H192C183.2 128 176 135.2 176 144S183.2 160 192 160h160c8.844 0 16-7.156 16-16S360.8 128 352 128zM352 224H192C183.2 224 176 231.2 176 240S183.2 256 192 256h160c8.844 0 16-7.156 16-16S360.8 224 352 224z"
				/>
			</symbol>
			<symbol id="icon-align-left" viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg">
				<path
					fill="currentColor"
					d="M272 48C280.8 48 288 55.16 288 64C288 72.84 280.8 80 272 80H16C7.164 80 0 72.84 0 64C0 55.16 7.164 48 16 48H272zM432 176C440.8 176 448 183.2 448 192C448 200.8 440.8 208 432 208H16C7.164 208 0 200.8 0 192C0 183.2 7.164 176 16 176H432zM0 320C0 311.2 7.164 304 16 304H272C280.8 304 288 311.2 288 320C288 328.8 280.8 336 272 336H16C7.164 336 0 328.8 0 320zM432 432C440.8 432 448 439.2 448 448C448 456.8 440.8 464 432 464H16C7.164 464 0 456.8 0 448C0 439.2 7.164 432 16 432H432z"
				/>
			</symbol>
			<symbol id="icon-arrow-right-from-bracket" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
				<path
					fill="currentColor"
					d="M507.3 244.7l-144-144c-6.25-6.25-16.38-6.25-22.62 0s-6.25 16.38 0 22.62L457.4 240H176C167.2 240 160 247.2 160 256s7.156 16 16 16h281.4l-116.7 116.7c-6.25 6.25-6.25 16.38 0 22.62s16.38 6.25 22.62 0l144-144C510.4 264.2 512 260.1 512 256S510.4 247.8 507.3 244.7zM176 448h-96C53.53 448 32 426.5 32 400v-288C32 85.53 53.53 64 80 64h96C184.8 64 192 56.84 192 48S184.8 32 176 32h-96C35.88 32 0 67.88 0 112v288C0 444.1 35.88 480 80 480h96C184.8 480 192 472.8 192 464S184.8 448 176 448z"
				/>
			</symbol>
			<symbol id="icon-magnifying-glass" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
				<path
					fill="currentColor"
					d="M507.3 484.7l-141.5-141.5C397 306.8 415.1 259.7 415.1 208c0-114.9-93.13-208-208-208S-.0002 93.13-.0002 208S93.12 416 207.1 416c51.68 0 98.85-18.96 135.2-50.15l141.5 141.5C487.8 510.4 491.9 512 496 512s8.188-1.562 11.31-4.688C513.6 501.1 513.6 490.9 507.3 484.7zM208 384C110.1 384 32 305 32 208S110.1 32 208 32S384 110.1 384 208S305 384 208 384z"
				/>
			</symbol>
			<symbol id="icon-bell" viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg">
				<path
					fill="currentColor"
					d="M207.1 16C207.1 7.164 215.2 0 223.1 0C232.8 0 240 7.164 240 16V32.79C320.9 40.82 384 109 384 192V221.1C384 264.8 401.4 306.7 432.3 337.7L435 340.4C443.3 348.7 448 359.1 448 371.7C448 396.2 428.2 416 403.7 416H44.28C19.83 416 0 396.2 0 371.7C0 359.1 4.666 348.7 12.97 340.4L15.72 337.7C46.63 306.7 64 264.8 64 221.1V192C64 109 127.1 40.82 208 32.79L207.1 16zM223.1 64C153.3 64 95.1 121.3 95.1 192V221.1C95.1 273.3 75.26 323.4 38.35 360.3L35.6 363C33.29 365.3 31.1 368.5 31.1 371.7C31.1 378.5 37.5 384 44.28 384H403.7C410.5 384 416 378.5 416 371.7C416 368.5 414.7 365.3 412.4 363L409.7 360.3C372.7 323.4 352 273.3 352 221.1V192C352 121.3 294.7 64 223.1 64H223.1zM223.1 480C237.9 480 249.8 471.1 254.2 458.7C257.1 450.3 266.3 445.1 274.6 448.9C282.9 451.9 287.3 461 284.4 469.3C275.6 494.2 251.9 512 223.1 512C196.1 512 172.4 494.2 163.6 469.3C160.7 461 165.1 451.9 173.4 448.9C181.7 445.1 190.9 450.3 193.8 458.7C198.2 471.1 210.1 480 223.1 480z"
				/>
			</symbol>
			<symbol id="icon-clock" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
				<path
					fill="currentColor"
					d="M240 112C240 103.2 247.2 96 256 96C264.8 96 272 103.2 272 112V247.4L360.9 306.7C368.2 311.6 370.2 321.5 365.3 328.9C360.4 336.2 350.5 338.2 343.1 333.3L247.1 269.3C242.7 266.3 239.1 261.3 239.1 256L240 112zM256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0zM32 256C32 379.7 132.3 480 256 480C379.7 480 480 379.7 480 256C480 132.3 379.7 32 256 32C132.3 32 32 132.3 32 256z"
				/>
			</symbol>
			<symbol id="icon-calendar" viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg">
				<path
					fill="currentColor"
					d="M112 0C120.8 0 128 7.164 128 16V64H320V16C320 7.164 327.2 0 336 0C344.8 0 352 7.164 352 16V64H384C419.3 64 448 92.65 448 128V448C448 483.3 419.3 512 384 512H64C28.65 512 0 483.3 0 448V128C0 92.65 28.65 64 64 64H96V16C96 7.164 103.2 0 112 0zM416 192H32V448C32 465.7 46.33 480 64 480H384C401.7 480 416 465.7 416 448V192zM384 96H64C46.33 96 32 110.3 32 128V160H416V128C416 110.3 401.7 96 384 96z"
				/>
			</symbol>
			<symbol id="icon-graduation-cap" viewBox="0 0 640 512" xmlns="http://www.w3.org/2000/svg">
				<path
					fill="currentColor"
					d="M617.8 136.7l-286.3-102.7C324 31.33 315.9 31.36 308.5 33.98L22.22 136.7C8.719 141.5 0 153.8 0 167.1s8.719 26.47 22.22 31.31L48 208.6v113.1l-31.59 138.8c-1.094 4.75 .0469 9.719 3.078 13.53S27.13 480 32.01 480h63.98c4.859 0 9.461-2.219 12.49-6c3.047-3.781 4.188-8.75 3.125-13.5L80 319.6V220l228.5 81.99c3.703 1.313 7.578 1.938 11.47 1.938c3.906 0 7.812-.6563 11.56-1.969l286.2-102.7C631.3 194.5 640 182.2 640 167.1S631.3 141.5 617.8 136.7zM52.08 447.1l12.09-52.75l11.84 52.75H52.08zM319.3 271.9L129.5 203.8l195.2-60.5c8.438-2.625 13.2-11.55 10.57-20.02c-2.609-8.407-11.49-13.23-20.01-10.55L79.4 185.8L32 168l288.7-103.9L608 168L319.3 271.9zM482.3 288.1c-8.766 1.156-14.94 9.188-13.78 17.97l11.36 86.63c1.156 8.907-4.656 17.66-14.8 22.34c-43.91 20.31-93.56 31.72-143.2 32.94H318.5c-50.02-1.219-99.67-12.63-143.6-32.94c-10.14-4.688-15.95-13.44-14.8-22.31L171.5 306.1c1.156-8.782-5.016-16.81-13.78-17.97C148.8 286.9 140.9 293.2 139.8 301.9l-11.36 86.69c-3.016 23.03 9.969 44.85 33.08 55.53C209.4 466.2 263.4 478.7 318.1 480h4.141c54.36-1.344 108.4-13.75 156.2-35.88c23.11-10.69 36.09-32.5 33.08-55.56l-11.36-86.66C499.1 293.2 491.1 286.9 482.3 288.1z"
				/>
			</symbol>
			<symbol id="icon-square-check" viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg">
				<path
					fill="currentColor"
					d="M203.3 331.3C197.1 337.6 186.9 337.6 180.7 331.3L116.7 267.3C110.4 261.1 110.4 250.9 116.7 244.7C122.9 238.4 133.1 238.4 139.3 244.7L192 297.4L308.7 180.7C314.9 174.4 325.1 174.4 331.3 180.7C337.6 186.9 337.6 197.1 331.3 203.3L203.3 331.3zM0 96C0 60.65 28.65 32 64 32H384C419.3 32 448 60.65 448 96V416C448 451.3 419.3 480 384 480H64C28.65 480 0 451.3 0 416V96zM32 96V416C32 433.7 46.33 448 64 448H384C401.7 448 416 433.7 416 416V96C416 78.33 401.7 64 384 64H64C46.33 64 32 78.33 32 96z"
				/>
			</symbol>
			<symbol id="icon-check" viewBox="0 0 14 11" xmlns="http://www.w3.org/2000/svg">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M13.7072 1.70718L5.00008 10.4143L0.292969 5.70718L1.70718 4.29297L5.00008 7.58586L12.293 0.292969L13.7072 1.70718Z"
					fill="currentColor"
				/>
			</symbol>

			<symbol id="icon-arrow-up-right-from-square" viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg">
				<path
					fill="currentColor"
					d="M272 64C263.2 64 256 56.84 256 48C256 39.16 263.2 32 272 32H432C440.8 32 448 39.16 448 48V208C448 216.8 440.8 224 432 224C423.2 224 416 216.8 416 208V86.63L187.3 315.3C181.1 321.6 170.9 321.6 164.7 315.3C158.4 309.1 158.4 298.9 164.7 292.7L393.4 64H272zM0 112C0 85.49 21.49 64 48 64H176C184.8 64 192 71.16 192 80C192 88.84 184.8 96 176 96H48C39.16 96 32 103.2 32 112V432C32 440.8 39.16 448 48 448H368C376.8 448 384 440.8 384 432V304C384 295.2 391.2 288 400 288C408.8 288 416 295.2 416 304V432C416 458.5 394.5 480 368 480H48C21.49 480 0 458.5 0 432V112z"
				/>
			</symbol>
			<symbol id="icon-sliders" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
				<path
					fill="currentColor"
					d="M0 416C0 407.2 7.164 400 16 400H81.6C89.01 363.5 121.3 336 160 336C198.7 336 230.1 363.5 238.4 400H496C504.8 400 512 407.2 512 416C512 424.8 504.8 432 496 432H238.4C230.1 468.5 198.7 496 160 496C121.3 496 89.01 468.5 81.6 432H16C7.164 432 0 424.8 0 416V416zM208 416C208 389.5 186.5 368 160 368C133.5 368 112 389.5 112 416C112 442.5 133.5 464 160 464C186.5 464 208 442.5 208 416zM352 176C390.7 176 422.1 203.5 430.4 240H496C504.8 240 512 247.2 512 256C512 264.8 504.8 272 496 272H430.4C422.1 308.5 390.7 336 352 336C313.3 336 281 308.5 273.6 272H16C7.164 272 0 264.8 0 256C0 247.2 7.164 240 16 240H273.6C281 203.5 313.3 176 352 176zM400 256C400 229.5 378.5 208 352 208C325.5 208 304 229.5 304 256C304 282.5 325.5 304 352 304C378.5 304 400 282.5 400 256zM496 80C504.8 80 512 87.16 512 96C512 104.8 504.8 112 496 112H270.4C262.1 148.5 230.7 176 192 176C153.3 176 121 148.5 113.6 112H16C7.164 112 0 104.8 0 96C0 87.16 7.164 80 16 80H113.6C121 43.48 153.3 16 192 16C230.7 16 262.1 43.48 270.4 80H496zM144 96C144 122.5 165.5 144 192 144C218.5 144 240 122.5 240 96C240 69.49 218.5 48 192 48C165.5 48 144 69.49 144 96z"
				/>
			</symbol>
			<symbol id="icon-chart-pie" viewBox="0 0 576 512" xmlns="http://www.w3.org/2000/svg">
				<path
					fill="currentColor"
					d="M320 240C311.2 240 304 232.8 304 224V15.47C304 7.074 310.5 .0432 318.9 .0026L320 0C443.7 0 544 100.3 544 224L543.1 225.1C543.1 233.5 536.9 240 528.5 240H320zM336 32.66V208H511.3C503.6 114.7 429.3 40.35 336 32.66V32.66zM256 49.61V288L412.5 444.5C419.2 451.2 418.7 462.2 411 467.7C371.8 495.6 323.8 512 272 512C139.5 512 32 404.6 32 272C32 150.7 122.1 50.34 238.1 34.25C248.2 32.99 256 40.36 256 49.61V49.61zM233.4 310.6C227.4 304.6 224 296.5 224 288V69.56C132.3 91.22 64 173.7 64 272C64 386.9 157.1 480 272 480C309.6 480 344.9 470 375.3 452.6L233.4 310.6zM499.9 447.3C493.9 452.1 484.5 452.5 478.7 446.7L347.3 315.3C337.2 305.2 344.4 288 358.6 288H558.4C567.6 288 575 295.8 573.8 305C566.1 360.9 539.1 410.6 499.9 447.3V447.3zM538.4 320H397.3L489.6 412.3C513.1 386.6 530.2 354.1 538.4 320z"
				/>
			</symbol>
			<symbol id="icon-person-from-portal" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
				<path
					fill="currentColor"
					d="M128 256c0 37.2-1.4 69-3.9 96h32.1c2.5-27.7 3.8-59.6 3.8-96c0-17-.3-33-.8-48c4 .2 8.1-1.1 11.4-3.9l52.4-45.8c5.7-5 12.6-8.6 20-10.4l1.5-.4c7.6-1.9 15.6-1.9 23.3 0l16.9 4.2L249.2 257.9c-7.3 21.8 2 45.7 22 57l93.3 52.5L320.9 490.7c-2.9 8.3 1.4 17.5 9.8 20.4s17.5-1.4 20.4-9.8L394.6 378c5.2-14.7-.9-30.9-14.5-38.5l-37.5-21.1c.2-.4 .4-.9 .5-1.3l37.2-111.6L394 253.2c5.9 20.6 24.7 34.8 46.2 34.8H496c8.8 0 16-7.2 16-16s-7.2-16-16-16H440.1c-7.1 0-13.4-4.7-15.4-11.6l-20.3-71c-4.7-16.5-17.9-29.2-34.5-33.4l-94.5-23.6c-12.7-3.2-26.1-3.2-38.8 0l-1.5 .4c-12.3 3.1-23.7 9-33.3 17.4l-44.6 39C148.1 46.7 117 0 80 0C35.8 0 0 66.6 0 256S35.8 512 80 512c23.3 0 44.3-18.6 59-64H105c-12.3 32-23.5 32-25 32h0 0c-1.6 0-13.6 0-26.4-35.8C40.9 409.1 32 349.5 32 256s8.9-153.1 21.5-188.2C66.4 32 78.4 32 80 32l0 0 0 0c1.6 0 13.6 0 26.4 35.8C119.1 102.9 128 162.5 128 256zM352 56a24 24 0 1 1 48 0 24 24 0 1 1 -48 0zm80 0A56 56 0 1 0 320 56a56 56 0 1 0 112 0zM358.4 170.1L314.3 302.4 286.9 287c-6.7-3.8-9.8-11.7-7.3-19l36.2-108.6 42.7 10.7zM226 314.1L197.3 384H112c-8.8 0-16 7.2-16 16s7.2 16 16 16h85.3c13 0 24.7-7.8 29.6-19.8l23.3-56.6c-10-6.7-18.2-15.5-24.1-25.4z"
				/>
			</symbol>
			<symbol id="icon-copy" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
				<path
					fill="currentColor"
					d="M272 416C263.2 416 256 423.2 256 432V448c0 17.67-14.33 32-32 32H64c-17.67 0-32-14.33-32-32V192c0-17.67 14.33-32 32-32h112C184.8 160 192 152.8 192 144C192 135.2 184.8 128 176 128H63.99c-35.35 0-64 28.65-64 64l.0098 256C0 483.3 28.65 512 64 512h160c35.35 0 64-28.65 64-64v-16C288 423.2 280.8 416 272 416zM502.6 86.63l-77.25-77.25C419.4 3.371 411.2 0 402.7 0H288C252.7 0 224 28.65 224 64v256c0 35.35 28.65 64 64 64h160c35.35 0 64-28.65 64-64V109.3C512 100.8 508.6 92.63 502.6 86.63zM416 45.25L466.7 96H416V45.25zM480 320c0 17.67-14.33 32-32 32h-160c-17.67 0-32-14.33-32-32V64c0-17.67 14.33-32 32-32h96l.0026 64c0 17.67 14.33 32 32 32H480V320z"
				/>
			</symbol>
			<symbol id="icon-trash-can" viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg">
				<path
					fill="currentColor"
					d="M144 400C144 408.8 136.8 416 128 416C119.2 416 112 408.8 112 400V176C112 167.2 119.2 160 128 160C136.8 160 144 167.2 144 176V400zM240 400C240 408.8 232.8 416 224 416C215.2 416 208 408.8 208 400V176C208 167.2 215.2 160 224 160C232.8 160 240 167.2 240 176V400zM336 400C336 408.8 328.8 416 320 416C311.2 416 304 408.8 304 400V176C304 167.2 311.2 160 320 160C328.8 160 336 167.2 336 176V400zM310.1 22.56L336.9 64H432C440.8 64 448 71.16 448 80C448 88.84 440.8 96 432 96H416V432C416 476.2 380.2 512 336 512H112C67.82 512 32 476.2 32 432V96H16C7.164 96 0 88.84 0 80C0 71.16 7.164 64 16 64H111.1L137 22.56C145.8 8.526 161.2 0 177.7 0H270.3C286.8 0 302.2 8.526 310.1 22.56V22.56zM148.9 64H299.1L283.8 39.52C280.9 34.84 275.8 32 270.3 32H177.7C172.2 32 167.1 34.84 164.2 39.52L148.9 64zM64 432C64 458.5 85.49 480 112 480H336C362.5 480 384 458.5 384 432V96H64V432z"
				/>
			</symbol>
			<symbol id="icon-sort-down" viewBox="0 0 320 512" xmlns="http://www.w3.org/2000/svg">
				<path
					fill="currentColor"
					d="M311.9 335.1l-132.4 136.8C174.1 477.3 167.1 480 160 480c-7.055 0-14.12-2.702-19.47-8.109l-132.4-136.8C-9.229 317.8 3.055 288 27.66 288h264.7C316.9 288 329.2 317.8 311.9 335.1z"
				/>
			</symbol>
			<symbol id="icon-angle-down" viewBox="0 0 384 512" xmlns="http://www.w3.org/2000/svg">
				<path
					fill="currentColor"
					d="M362.7 203.9l-159.1 144c-6.125 5.469-15.31 5.469-21.44 0L21.29 203.9C14.73 197.1 14.2 187.9 20.1 181.3C26.38 174.4 36.5 174.5 42.73 180.1L192 314.5l149.3-134.4c6.594-5.877 16.69-5.361 22.62 1.188C369.8 187.9 369.3 197.1 362.7 203.9z"
				/>
			</symbol>
			<symbol id="icon-angle-left" viewBox="0 0 256 512" xmlns="http://www.w3.org/2000/svg">
				<path
					fill="currentColor"
					d="M203.9 405.3c5.877 6.594 5.361 16.69-1.188 22.62c-6.562 5.906-16.69 5.375-22.59-1.188L36.1 266.7c-5.469-6.125-5.469-15.31 0-21.44l144-159.1c5.906-6.562 16.03-7.094 22.59-1.188c6.918 6.271 6.783 16.39 1.188 22.62L69.53 256L203.9 405.3z"
				/>
			</symbol>
			<symbol id="icon-angles-left" viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg">
				<path
					fill="currentColor"
					d="M202.7 84.1C196.1 78.2 186 78.73 180.1 85.29L36.1 245.3c-5.469 6.125-5.469 15.31 0 21.44l144 159.1c5.906 6.562 16.03 7.094 22.59 1.188c6.549-5.938 7.064-16.03 1.188-22.62L69.53 256l134.4-149.3C209.5 100.5 209.6 90.38 202.7 84.1zM261.5 256l134.4-149.3c5.596-6.23 5.73-16.35-1.188-22.62c-6.562-5.906-16.69-5.375-22.59 1.188l-144 159.1c-5.469 6.125-5.469 15.31 0 21.44l144 159.1c5.906 6.562 16.03 7.094 22.59 1.188c6.549-5.938 7.064-16.03 1.188-22.62L261.5 256z"
				/>
			</symbol>
			<symbol id="icon-xmark" viewBox="0 0 320 512" xmlns="http://www.w3.org/2000/svg">
				<path
					fill="currentColor"
					d="M315.3 411.3c-6.253 6.253-16.37 6.253-22.63 0L160 278.6l-132.7 132.7c-6.253 6.253-16.37 6.253-22.63 0c-6.253-6.253-6.253-16.37 0-22.63L137.4 256L4.69 123.3c-6.253-6.253-6.253-16.37 0-22.63c6.253-6.253 16.37-6.253 22.63 0L160 233.4l132.7-132.7c6.253-6.253 16.37-6.253 22.63 0c6.253 6.253 6.253 16.37 0 22.63L182.6 256l132.7 132.7C321.6 394.9 321.6 405.1 315.3 411.3z"
				/>
			</symbol>
			<symbol id="icon-pencil" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
				<path
					fill="currentColor"
					d="M373.2 16.97C395.1-4.901 430.5-4.901 452.4 16.97L495 59.6C516.9 81.47 516.9 116.9 495 138.8L182.3 451.6C170.9 462.9 156.9 471.2 141.5 475.8L20.52 511.3C14.9 512.1 8.827 511.5 4.687 507.3C.5466 503.2-1.002 497.1 .6506 491.5L36.23 370.5C40.76 355.1 49.09 341.1 60.44 329.7L373.2 16.97zM429.8 39.6C420.4 30.22 405.2 30.22 395.8 39.6L341 94.4L417.6 170.1L472.4 116.2C481.8 106.8 481.8 91.6 472.4 82.23L429.8 39.6zM109.6 402.4L173.4 415.2L394.1 193.6L318.4 117L96.84 338.6L109.6 402.4zM70.51 370.2C69.08 373.2 67.88 376.3 66.93 379.5L39.63 472.4L132.4 445.1C135.7 444.1 138.8 442.9 141.8 441.5L92.86 431.7C86.53 430.4 81.58 425.5 80.31 419.1L70.51 370.2z"
				/>
			</symbol>
			<symbol id="icon-paperclip" viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg">
				<path
					fill="currentColor"
					d="M380.7 67.31C351.6 38.22 304.4 38.22 275.3 67.31L83.31 259.3C36.54 306.1 36.54 381.9 83.31 428.7C130.1 475.5 205.9 475.5 252.7 428.7L404.7 276.7C410.9 270.4 421.1 270.4 427.3 276.7C433.6 282.9 433.6 293.1 427.3 299.3L275.3 451.3C216 510.6 119.1 510.6 60.69 451.3C1.417 392 1.418 295.1 60.69 236.7L252.7 44.69C294.3 3.092 361.7 3.092 403.3 44.69C444.9 86.28 444.9 153.7 403.3 195.3L219.5 379.1C191.3 407.3 144.9 404.7 119.1 373.6C98.67 346.1 100.8 308.6 124.9 284.5L276.7 132.7C282.9 126.4 293.1 126.4 299.3 132.7C305.6 138.9 305.6 149.1 299.3 155.3L147.5 307.1C134.1 319.7 133.8 339.7 144.1 353.6C157.1 369.8 182.2 371.2 196.9 356.5L380.7 172.7C409.8 143.6 409.8 96.41 380.7 67.31L380.7 67.31z"
				/>
			</symbol>
			<symbol id="icon-arrow-down-to-line" viewBox="0 0 384 512" xmlns="http://www.w3.org/2000/svg">
				<path
					fill="currentColor"
					d="M180.7 395.3C183.8 398.4 187.9 400 192 400s8.188-1.562 11.31-4.688l144-144c6.25-6.25 6.25-16.38 0-22.62s-16.38-6.25-22.62 0L208 345.4V48C208 39.16 200.8 32 192 32S176 39.16 176 48v297.4L59.31 228.7c-6.25-6.25-16.38-6.25-22.62 0s-6.25 16.38 0 22.62L180.7 395.3zM368 448h-352C7.156 448 0 455.2 0 464S7.156 480 16 480h352c8.844 0 16-7.156 16-16S376.8 448 368 448z"
				/>
			</symbol>
			<symbol id="icon-eye" viewBox="0 0 576 512" xmlns="http://www.w3.org/2000/svg">
				<path
					fill="currentColor"
					d="M416 256C416 326.7 358.7 384 288 384C217.3 384 160 326.7 160 256C160 185.3 217.3 128 288 128C358.7 128 416 185.3 416 256zM288 160C234.1 160 192 202.1 192 256C192 309 234.1 352 288 352C341 352 384 309 384 256C384 202.1 341 160 288 160zM480.6 112.6C527.4 156 558.7 207.1 573.5 243.7C576.8 251.6 576.8 260.4 573.5 268.3C558.7 304 527.4 355.1 480.6 399.4C433.5 443.2 368.8 480 288 480C207.2 480 142.5 443.2 95.42 399.4C48.62 355.1 17.34 304 2.461 268.3C-.8205 260.4-.8205 251.6 2.461 243.7C17.34 207.1 48.62 156 95.42 112.6C142.5 68.84 207.2 32 288 32C368.8 32 433.5 68.84 480.6 112.6V112.6zM32 256C45.33 288 74.13 336 117.2 376C160.3 416 217.6 448 288 448C358.4 448 415.7 416 458.8 376C501.9 336 530.7 288 544 256C530.7 223.1 501.9 175.1 458.8 136C415.7 95.99 358.4 64 288 64C217.6 64 160.3 95.99 117.2 136C74.13 175.1 45.33 223.1 32 256V256z"
				/>
			</symbol>
			{/*TODO: convert*/}
			<symbol id="icon-eye-slash" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
				<g stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
					<path d="M18.8574 11.9348L20.9958 15.6387" />
					<path d="M14.4541 13.9934L15.121 17.7756" />
					<path d="M9.53714 13.9917L8.87012 17.7746" />
					<path d="M5.13799 11.9319L2.98926 15.6536" />
					<path d="M3 9.83203C4.57617 11.783 7.4657 14.25 12.0001 14.25C16.5344 14.25 19.4239 11.783 21.0001 9.83205" />
				</g>
			</symbol>
		</svg>
	)
}

export default AppIcons
