import {Fragment, useMemo, memo} from 'react'

import {useRouter} from 'next/router'

import {useAtom, useAtomValue, useSetAtom} from 'jotai'
import {AppState} from '~states/App'
import {ModalState} from '~states/Modal'
import {LocaleState} from '~states/Locale'
import {UserState} from '~states/User'

import {logout} from '~actions/auth'

import {amplitudeSetUserProperties, amplitudeTrackEvent} from '~hooks/useAmplitude'
import {setCookie} from '~hooks/useCookie'

import {Icon, Link} from '~ui/Modules'

import {APP_PERMISSIONS, APP_PERMISSIONS_ACCESSES, AppPermissionsType} from '~types/_app'
import type {SetStateAction} from 'jotai'

interface Props {
	className?: string
	setOpen: (update?: SetStateAction<string>) => void
}

interface MobileMenuNavigationItemInterface {
	thread_id: string
	menutitle: string
	route?: string
	icon?: string
	permissions?: APP_PERMISSIONS[]
	onClick?: (e: any) => void
	className?: string
	childrens?: MobileMenuNavigationItemInterface[]
}

interface MobileMenuListItemProps {
	data: MobileMenuNavigationItemInterface
	level?: number
	openedThreads?: string[]
	setOpen: (update?: SetStateAction<string>) => void
	onThreadToggle: (string) => void
	userPermissions: any[] | AppPermissionsType
}

const MobileMenuSidebar = ({setOpen}: Props) => {
	const [app, setApp] = useAtom(AppState)
	const locale = useAtomValue(LocaleState)
	const user = useAtomValue(UserState)
	const setModal = useSetAtom(ModalState)

	//TODO: проверить ограничение прав (возможно, не работают)
	//TODO: добавить для раздела массив всех прав, которые используются в треде
	const userPermissions = useMemo<AppPermissionsType | any[]>(() => user['profile']?.['permissions'] || [], [user])
	const navigation = [
		{
			icon: '#icon-user-headset',
			menutitle: locale['SUPPORT']['MENUTITLE'],
			onClick: () => setModal('support'),
		},
		{
			thread_id: 'company',
			icon: '#icon-sliders',
			menutitle: locale['COMPANY']['MENUTITLE'],
			permissions: [APP_PERMISSIONS.COMPANY, APP_PERMISSIONS.BILLING, APP_PERMISSIONS.USERS, APP_PERMISSIONS.MANAGERS],
			route: '/company',
			childrens: [
				{
					menutitle: locale['BILLING']['MENUTITLE'],
					permissions: [APP_PERMISSIONS.BILLING],
					route: '/company/billing/',
				},
				{
					menutitle: locale['DEPARTMENTS']['MENUTITLE'],
					route: '/company/departments/',
				},
				{
					menutitle: locale['POSITIONS']['MENUTITLE'],
					route: '/company/positions/',
				},
				{
					menutitle: locale['USERS']['MENUTITLE'],
					route: '/company/users/',
					permissions: [APP_PERMISSIONS.MANAGERS],
				},
				{
					menutitle: locale['INVITES']['MENUTITLE'],
					route: '/company/invites/',
					permissions: [APP_PERMISSIONS.MANAGERS],
				},
				{
					menutitle: locale['MANAGERS']['MENUTITLE'],
					route: '/company/managers/',
					permissions: [APP_PERMISSIONS.MANAGERS],
				},
			],
		},
		/*
		{
			icon: '#icon-bell',
			menutitle: locale['NOTIFICATIONS']['MENUTITLE'],
			//todo:
			//permissions: [APP_PERMISSIONS.ARTICLES],
			route: '/notifications/',
		},
		*/
		{
			icon: '#icon-newspaper',
			menutitle: locale['ARTICLES']['MENUTITLE'],
			permissions: [APP_PERMISSIONS.ARTICLES],
			route: '/articles/',
		},
		{
			thread_id: 'schedules',
			icon: '#icon-calendar-check',
			menutitle: locale['SCHEDULES']['MENUTITLE'],
			permissions: [APP_PERMISSIONS.INSPECTIONS, APP_PERMISSIONS.COURSES],
			route: '/schedules/',
		},
		{
			thread_id: 'inspections',
			icon: '#icon-square-check',
			menutitle: locale['INSPECTIONS']['MENUTITLE'],
			permissions: [APP_PERMISSIONS.INSPECTIONS, APP_PERMISSIONS.FORMS],
			route: '/inspections',
			childrens: [
				{
					menutitle: locale['INSPECTIONS_REJECTIONS']['MENUTITLE'],
					route: '/inspections/rejections/',
					permissions: [APP_PERMISSIONS.FORMS],
				},
				{
					menutitle: locale['INSPECTIONS_TEMPLATES']['MENUTITLE'],
					route: '/inspections/templates/',
					permissions: [APP_PERMISSIONS.FORMS],
				},
				{
					menutitle: locale['REPEATABLE_INSPECTIONS']['MENUTITLE'],
					route: '/inspections/repeatable/',
					permissions: [APP_PERMISSIONS.INSPECTIONS],
				},
			],
		},
		{
			thread_id: 'courses',
			icon: '#icon-graduation-cap',
			menutitle: locale['COURSES']['THREAD_MENUTITLE'],
			permissions: [APP_PERMISSIONS.COURSES, APP_PERMISSIONS.FORMS],
			route: '/courses',
			childrens: [
				{
					menutitle: locale['COURSES_REJECTIONS']['MENUTITLE'],
					route: '/courses/rejections/',
					permissions: [APP_PERMISSIONS.FORMS],
				},
				{
					menutitle: locale['COURSES_TEMPLATES']['MENUTITLE'],
					route: '/courses/templates/',
					permissions: [APP_PERMISSIONS.FORMS],
				},
				{
					menutitle: locale['COURSES']['MENUTITLE'],
					route: '/courses/',
					permissions: [APP_PERMISSIONS.COURSES],
				},
			],
		},
		{
			thread_id: 'statistics',
			icon: '#icon-chart-pie',
			menutitle: locale['STATISTICS']['THREAD_MENUTITLE'],
			permissions: [APP_PERMISSIONS.STATISTICS, APP_PERMISSIONS.COURSES, APP_PERMISSIONS.INSPECTIONS, APP_PERMISSIONS.USERS, APP_PERMISSIONS.ARTICLES],
			route: '/statistics',
			childrens: [
				/*TODO: заменить на отображение ТОЛЬКО общего раздела (ПРОВЕРКИ), а во все внутренние (перепроверки, задания) попадать уже из него*/
				{
					menutitle: locale['STATISTICS_REPEATABLE_INSPECTIONS']['MENUTITLE'],
					//TODO: change to permission of current category?
					//permissions: [APP_PERMISSIONS.BILLING],
					route: '/statistics/inspections/repeatable/',
				},
				{
					menutitle: locale['STATISTICS_COURSES']['MENUTITLE'],
					//TODO: change to permission of current category?
					//permissions: [APP_PERMISSIONS.BILLING],
					route: '/statistics/courses/',
				},
				{
					menutitle: locale['STATISTICS_USERS']['MENUTITLE'],
					//TODO: change to permission of current category?
					//permissions: [APP_PERMISSIONS.BILLING],
					route: '/statistics/users/',
				},
				{
					menutitle: locale['STATISTICS_ARTICLES']['MENUTITLE'],
					//TODO: change to permission of current category?
					//permissions: [APP_PERMISSIONS.BILLING],
					route: '/statistics/articles/',
				},
				{
					menutitle: locale['STATISTICS']['MENUTITLE'],
					route: '/statistics/',
				},
			],
		},
	] as MobileMenuNavigationItemInterface[]

	const toggleThreadHandler = (thread_id: string) => {
		const openedThreads = app['aside']['opened']

		if (openedThreads.includes(thread_id)) {
			openedThreads.splice(openedThreads.indexOf(thread_id), 1)
		} else {
			openedThreads.push(thread_id)
		}

		setCookie('aside.opened', JSON.stringify(openedThreads), {
			path: '/',
		})

		setApp((app) => ({
			...app,
			aside: {
				opened: openedThreads,
			},
		}))
	}

	return (
		<>
			<div className="ui-sidebar-header">
				<Link href="/" onClick={() => setOpen(null)} className="tw-inline-flex tw-items-center tw-gap-x-[20px]">
					<Icon className="tw-h-[32px] tw-w-[32px] tw-flex-shrink-0 tw-text-logo" name="#icon-app-logo" />
					<span className="tw-text-[20px] tw-font-bold tw-leading-[24px] tw-text-white">{process.env.NEXT_PUBLIC_APP_NAME}</span>
				</Link>

				<button type="button" onClick={() => setOpen(null)} className="ui-sidebar-button-close">
					<Icon name="#icon-xmark" />
				</button>
			</div>
			{user?.['profile'] && (
				<div className="tw-flex tw-flex-col tw-divide-y tw-divide-disabled tw-bg-white">
					{user['profile']?.['tenant']?.['display_name'] && <div className="ui-h4 tw-px-[36px] tw-py-[24px]">{user['profile']['tenant']['display_name']}</div>}

					<div className="tw-flex tw-items-center tw-justify-between tw-gap-x-[36px] tw-px-[36px] tw-py-[24px]">
						<Link href={user?.['profile']?.['id'] ? ('/company/managers/' + user['profile']['id']) : '/'} onClick={() => setOpen(null)} className="tw-flex tw-flex-col tw-gap-y-[4px] tw-leading-none">
							{user?.['profile']?.['name'] && (
								<div className="tw-font-medium">{user['profile']['name']}</div>
							)}
							{user?.['profile']?.['email'] && (
								<div className="tw-text-[14px] tw-text-secondary-on-surface">{user['profile']['email']}</div>
							)}
						</Link>

						<button
							type="button"
							onClick={async () => {
								amplitudeTrackEvent('LOGGED_OUT')
								amplitudeSetUserProperties()

								await logout()
							}}
						>
							<Icon name="#icon-arrow-right-from-bracket" className="tw-h-[20px] tw-w-[20px]" />
						</button>
					</div>
				</div>
			)}
			<div className="ui-sidebar-body">
				<div className="ui-sidebar-content">
					<div className="ui-app-mobile-menu">
						<ul className="ui-app-mobile-menu-items">
							{navigation.map((item) => (
								<Fragment key={item['menutitle']}>
									{(!item['permissions'] ||
										item['permissions'].some((key) => Object.keys(userPermissions).includes(key) && !APP_PERMISSIONS_ACCESSES.NOT_ALLOWED.includes(userPermissions[key]))) && (
										<MobileMenuSidebar.ListItem
											data={item}
											level={0}
											setOpen={setOpen}
											openedThreads={app['aside']['opened']}
											onThreadToggle={toggleThreadHandler}
											userPermissions={userPermissions}
										/>
									)}
								</Fragment>
							))}
						</ul>
					</div>
				</div>
			</div>
		</>
	)
}

const ListItem = ({data, level = 0, openedThreads = [], onThreadToggle, setOpen, userPermissions, ...props}: MobileMenuListItemProps) => {
	const router = useRouter()

	return (
		<li
			className={
				'ui-app-mobile-menu-item' +
				(data['route'] && router['asPath'].startsWith(data['route']) && (!data?.['thread_id'] || !openedThreads.includes(data['thread_id'])) ? ' ui-app-mobile-menu-item-active' : '')
			}
		>
			{data?.['route'] && !data?.['childrens'] && (
				<Link href={data['route']} onClick={() => setOpen(null)} className={'ui-app-mobile-menu-link' + (data?.['className'] ? ' ' + data['className'] : '')} {...props}>
					<>
						<div className="tw-h-[22px] tw-w-[22px] tw-flex-shrink-0">{data?.['icon'] && <Icon className="tw-h-full tw-w-full" name={data['icon']} />}</div>

						<span className="tw-text-left">{data['menutitle']}</span>
					</>
				</Link>
			)}

			{data?.['onClick'] && (
				<button type="button" onClick={data['onClick']} className={'ui-app-mobile-menu-link' + (data?.['className'] ? ' ' + data['className'] : '')} {...props}>
					<div className="tw-h-[22px] tw-w-[22px] tw-flex-shrink-0">{data?.['icon'] && <Icon className="tw-h-full tw-w-full" name={data['icon']} />}</div>

					<span className="tw-text-left">{data['menutitle']}</span>
				</button>
			)}

			{data?.['thread_id'] && data?.['childrens'] && (
				<>
					<button type="button" onClick={() => onThreadToggle(data['thread_id'])} className={'ui-app-mobile-menu-link' + (data?.['className'] ? ' ' + data['className'] : '')} {...props}>
						<div className="tw-h-[22px] tw-w-[22px] tw-flex-shrink-0">{data?.['icon'] && <Icon className="tw-h-full tw-w-full" name={data['icon']} />}</div>

						<span className="tw-text-left">{data['menutitle']}</span>
					</button>

					{openedThreads.includes(data['thread_id']) && (
						<ul className="ui-app-mobile-menu-items">
							{data['childrens'].map(
								(children) =>
									(!children['permissions'] ||
										children['permissions'].some((key) => Object.keys(userPermissions).includes(key) && !APP_PERMISSIONS_ACCESSES.NOT_ALLOWED.includes(userPermissions[key]))) && (
										<MobileMenuSidebar.ListItem
											key={children['menutitle']}
											data={children}
											level={level + 1}
											setOpen={setOpen}
											openedThreads={openedThreads}
											onThreadToggle={onThreadToggle}
											userPermissions={userPermissions}
										/>
									)
							)}
						</ul>
					)}
				</>
			)}
		</li>
	)
}
MobileMenuSidebar.ListItem = ListItem

export default memo(MobileMenuSidebar)
