import {useAtom} from 'jotai'
import {SidebarState} from '~states/Sidebar'

import {Sidebar} from '~ui/Modules'

import MobileMenuSidebar from '~chunks/_app/sidebar/MobileMenu'

const AppSidebars = () => {
	const [sidebar, setSidebar] = useAtom(SidebarState)

	return (
		<>
			<Sidebar id="sidebar-mobile-menu" isOpen={sidebar === 'mobile-menu'} className="ui-sidebar-primary ui-sidebar-right tw-w-full">
				<MobileMenuSidebar setOpen={setSidebar} />
			</Sidebar>
		</>
	)
}

export default AppSidebars
