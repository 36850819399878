import {atom} from 'jotai'

import RussianLocale from '~locales/ru.json'

import {LanguageState} from '~states/Language'

//TODO: add missing locales
export const LocaleState = atom((get) => {
	switch (get(LanguageState)) {
		case 'ru':
			return RussianLocale

		default:
			return RussianLocale
	}
})
