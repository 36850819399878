import {Dialog} from '@headlessui/react'
import {ComponentProps, Fragment, memo, ReactNode} from 'react'

import {SetStateAction} from 'jotai'

import {Transition} from '~ui/Modules'

interface Props extends ComponentProps<'div'> {
	id: string,
	children: ReactNode
	className?: string
	state: string | false
	setState: (update?: SetStateAction<string>) => void
}

const Overlay = memo(({id, children, className = '', state, setState, ...props}: Props) => {
	return (
		<Transition
			as={Fragment}
			show={state == id}
			enter="tw-transition-opacity tw-ease-out tw-duration-200"
			enterFrom="tw-opacity-0"
			enterTo="tw-opacity-100"
			leave="tw-transition-opacity tw-ease-in tw-duration-100"
			leaveFrom="tw-opacity-100"
			leaveTo="tw-opacity-0"
			appear
		>
			<Dialog as="div" className={'ui-overlay' + (className && ' ' + className)} onClose={() => setState(null)} {...props}>
				<div className="ui-overlay-content">
					<div className="ui-overlay-backdrop" onClick={() => setState(null)} />

					{children}
				</div>
			</Dialog>
		</Transition>
	)
})

export {Overlay}
