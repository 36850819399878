import {ReactNode} from 'react'
import {FieldError, FieldErrorsImpl, Merge} from 'react-hook-form'

import {useDebounce} from '~hooks/useDebounce'
import {localeReplace} from '~helpers/functions'

interface Props {
	children: ReactNode
	name: string
	htmlFor?: string
	label?: string
	maxLength?: number
	//TODO: add ability to pass HTML (ReactNode)
	description?: string | ReactNode
	error?: FieldError | Merge<FieldError, FieldError[]> | Merge<FieldError, FieldErrorsImpl<any>>
	errorsLocale?: Object
	className?: string
	value?: string | number
}

interface CounterProps {
	value?: string | number
	maxLength?: number
}

interface ErrorProps {
	error?: FieldError | Merge<FieldError, FieldError[]> | Merge<FieldError, FieldErrorsImpl<any>>
	errorsLocale?: Object
}

//TODO: make ref (forwardRef), to pass all in children AND make onClick event on label, for simulate click on child
const Field = ({children, name, label = '', htmlFor = '', error = undefined, errorsLocale = {}, className = '', maxLength = null, description = '', value = undefined, ...props}: Props) => {
	return (
		<div className={'ui-field' + (className && ' ' + className) + (error ? ' ui-field-error' : '')} {...props}>
			{label && (
				<label htmlFor={htmlFor} className="ui-label">
					{label}
				</label>
			)}

			{children}

			{(description || maxLength) && (
				<div className="ui-field-helpers">
					{description && <div className="ui-field-helper ui-field-description">{description}</div>}

					{maxLength && <Field.Counter value={value} maxLength={maxLength} />}
				</div>
			)}

			{error && <Field.Error error={error} errorsLocale={errorsLocale} />}
		</div>
	)
}

const Counter = ({value = undefined, maxLength}: CounterProps) => {
	const valueLength = useDebounce(value ? String(value).length : 0, 100)

	return (
		<div className={'ui-field-helper ui-field-counter' + (valueLength > maxLength ? ' ui-field-helper-error' : '')}>
			{valueLength}/{maxLength}
		</div>
	)
}
Field.Counter = Counter

const Error = ({error, errorsLocale = {}}: ErrorProps) => {
	//TODO: add fieldname as first vale ALWAYS
	//TODO: add useCallback
	//TODO: add missing types
	const renderErrorMessage = (error, errorsLocale) => {
		//TODO: убрать в следующих проектах, или когда переделаем локали (с апперкейса)
		const error_key = error['type']?.toUpperCase()

		let message

		if (errorsLocale?.[error_key]) {
			switch (error['type']) {
				case 'invalid_type':
					message = localeReplace(errorsLocale[error_key], 'expected', error['message'] || '')

					break

				case 'too_small':
					message = localeReplace(errorsLocale[error_key], 'minimum', error['message'] || '')

					break

				case 'too_big':
					message = localeReplace(errorsLocale[error_key], 'maximum', error['message'] || '')

					break

				default:
					message = errorsLocale[error_key]
			}
		}

		return (
			<>
				{message && (
					<div className="ui-field-errors">
						{/*TODO: в будущем сделать возможность возвращения сразу нескольких ошибок*/}
						<div className="ui-field-error">{message}</div>
					</div>
				)}
			</>
		)
	}

	return renderErrorMessage(error, errorsLocale)
}
Field.Error = Error

export {Field}
