import {AnchorHTMLAttributes, forwardRef, ReactNode} from 'react'
import {useRouter} from 'next/router'

interface Props extends AnchorHTMLAttributes<HTMLAnchorElement> {
	href?: string
	children?: ReactNode
	prefetch?: boolean
	onClick?: (e: any) => void
}

const Link = forwardRef<HTMLAnchorElement, Props>(({href = '', children = null, prefetch = true, onClick = null, ...props}, ref) => {
	const router = useRouter()

	async function handleClick(e) {
		e.preventDefault()
		e.stopPropagation()

		if (router) {
			if (prefetch) {
				//router.prefetch(href)
			}

			await router.push(href)

			if (onClick) {
				onClick(e)
			}
		}
	}

	return (
		<a href={href} onClick={handleClick} ref={ref} {...props}>
			{children}
		</a>
	)
})

export {Link}
