import {atom} from 'jotai'

import type {AppStateInterface} from '~types/_app'

export const AppState = atom<AppStateInterface>({
	init: false,
	loading: false,
	aside: {
		opened: [],
	},
	filters: {
		opened: false,
	},
})
