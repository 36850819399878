import {useAtom, useAtomValue, useSetAtom} from 'jotai'
import {AppState} from '~states/App'
import {ModalState} from '~states/Modal'
import {LocaleState} from '~states/Locale'

import {supportSchema} from '~schemas/_app'

import {amplitudeTrackEvent} from '~hooks/useAmplitude'
import {schema} from '~hooks/useForm'

import {Overlay} from '~ui/Modules'

import SupportModal from '~chunks/_app/modal/Support'

type FormData = schema.infer<typeof supportSchema>

const AppModals = () => {
	const locale = useAtomValue(LocaleState)
	const [modal, setModal] = useAtom(ModalState)
	const setApp = useSetAtom(AppState)

	const supportHandler = async (data: FormData, resetForm: any) => {
		setApp((app) => ({
			...app,
			loading: true,
		}))

		// TODO: make!

		// if (response['success']) {
		// 	amplitudeTrackEvent('SUPPORT_CREATE_FINISHED')

		// 	Notification.success(locale['SUPPORT']['NOTIFICATIONS']['CREATE_SUCCESS'])

		// 	setModal(false)
		// } else {
		// 	switch (response['status']) {
		// 		case 403:
		// 			Notification.error(locale['_APP']['NOTIFICATIONS']['PERMISSION_DENIED'])

		// 			break

		// 		default:
		// 			Notification.error(locale['SUPPORT']['NOTIFICATIONS']['CREATE_FAILED'])
		// 	}
		// }

		setApp((app) => ({
			...app,
			loading: false,
		}))
	}

	return (
		<>
			<Overlay id="support" state={modal} setState={setModal} className="ui-overlay-primary ui-overlay-unclickable">
				<SupportModal
					onClose={() => setModal(false)}
					onSubmit={supportHandler}
					onCancel={() => {
						amplitudeTrackEvent('SUPPORT_CANCELED')
						setModal(false)
					}}
				/>
			</Overlay>
		</>
	)
}

export default AppModals
