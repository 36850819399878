export interface AppStateInterface {
	init: boolean
	loading: boolean
	aside: AppAsideStateInterface
	filters: AppFiltersStateInterface
}
export interface AppAsideStateInterface {
	opened: string[]
}
export interface AppFiltersStateInterface {
	opened: boolean
}

export interface AppRequestMeta {
	limit: number
	total: number
	current_page: number
	last_page: number
}

export type AppLanguage = 'en' | 'ru'

export type AppDisplayTypes = 'tiles' | 'list'

//Todo: привести к одному склонению

export enum APP_PERMISSIONS {
	COURSES = 'courses',
	STATISTICS = 'dashboard',
	FEEDBACKS = 'feedback',
	COMPANY = 'settings',
	MANAGERS = 'user',
	USERS = 'mobile_users',
	ARTICLES = 'publications',
	BILLING = 'billing',
	INSPECTIONS = 'inspections',
	FORMS = 'forms',
}

export enum APP_PERMISSIONS_ACCESSES {
	NOT_ALLOWED = '',
	READ = 'read',
	WRITE = 'write',
	DISABLED = 'disabled',
}

export type AppPermissionsType = {
	[key in APP_PERMISSIONS]: APP_PERMISSIONS_ACCESSES
}
