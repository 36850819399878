import {useAtomValue} from 'jotai'
import {LocaleState} from '~states/Locale'

import {FieldErrors, schema, UseFormHandleSubmit, UseFormRegister, UseFormReset} from '~hooks/useForm'

import {supportSchema} from '~schemas/_app'

import {Field, Input, Textarea} from '~ui/Forms'

type FormData = schema.infer<typeof supportSchema>

interface Props {
	formHandleSubmit: UseFormHandleSubmit<FormData>
	onSubmit: (data: FormData, resetForm: any) => void
	formReset: UseFormReset<FormData>
	formRegister: UseFormRegister<FormData>
	formErrors: FieldErrors<FormData>
}

const CustomRequestForm = ({formHandleSubmit, onSubmit, formReset, formRegister, formErrors}: Props) => {
	const locale = useAtomValue(LocaleState)

	return (
		<form onSubmit={formHandleSubmit((data: FormData) => onSubmit(data, formReset))} className="tw-flex tw-flex-col tw-gap-y-[40px]" autoComplete="off">
			<div className="tw-flex tw-flex-col tw-gap-y-[20px]">
				<Field name="title" error={formErrors?.['title']} label={locale['SUPPORT']['FORMS']['DEFAULT']['FIELDS']['TITLE']['LABEL']} className="ui-field-primary">
					<Input className="ui-input-primary tw-max-w-full" placeholder={locale['SUPPORT']['FORMS']['DEFAULT']['FIELDS']['TITLE']['PLACEHOLDER']} {...formRegister('title')} />
				</Field>

				<Field name="text" error={formErrors?.['description']} label={locale['SUPPORT']['FORMS']['DEFAULT']['FIELDS']['TEXT']['LABEL']} className="ui-field-primary">
					<Textarea className="ui-textarea-primary" placeholder={locale['SUPPORT']['FORMS']['DEFAULT']['FIELDS']['TEXT']['PLACEHOLDER']} {...formRegister('description')} />
				</Field>
			</div>
		</form>
	)
}

export default CustomRequestForm
